$(document).ready(function() {


	// Padding top on Main based on header height

	function paddingtopFirstSection() {
		var $hh = $('header').outerHeight(true);
		$('main>section:first-of-type').css({'padding-top': 'calc(3rem + '+$hh+'px)'});
	}
	paddingtopFirstSection();	


	// Owl Carousel
	// Portfolio

	$('#portfolio-carousel').owlCarousel({
		loop: true,
		margin: 0,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 4000,
		responsive: {
			0: {
				items: 2
			},
			768: {
				items: 3
			},
			1200: {
				items: 4
			},
		}
	});

	// Clients carousel

	$('#clients-carousel').owlCarousel({
		loop: true,
		margin: 50,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 4000,
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 3
			},
			1200: {
				items: 6
			}
		}
	});

	// Solutions carousel

	$('#solutions-carousel').owlCarousel({
		loop: true,
		margin: 50,
		nav: false,
		dots: true,
		autoplay: true,
		autoplayTimeout: 4000,
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 3
			},
			1200: {
				items: 5
			}
		}
	});

	// Modal

	$('#tmm').on('show.bs.modal', function (event) {

		var button = $(event.relatedTarget);
		var title = button.data('title');
		var profile = button.data('profile');
		var linkedin = button.data('linkedin');
		var content = button.data('content');
		var modal = $(this);

		modal.find('.modal-title').text(title);
		modal.find('img.rounded-circle').attr('src', profile);
		modal.find('.btn-linkedin').attr('href', linkedin);
		modal.find('.modal-body .modal-body-content').html(content);
		
	});



	// Hover Magazine post

	$('.magazine-post, .featured-single-magazine').on({
		mouseenter: function () {
			$(this).addClass('hover');
		},
		mouseleave: function () {
			$(this).removeClass('hover');
		}
	});


	// Contact Form

	$('#nomesia_form input[type="submit"]').prop('value','Invia la richiesta');
	$('#nomesia_form #privacy_container a')
		.attr('onclick', '')
		.attr('target', '_blank')
		.attr('href', 'http://www.nomesia.com/privacy-policy');


	// Other forms

	$('.required').prev().append('<span class="ast">*</span>');

	$('#after_seminary_form').on('submit', function(e){

		$.ajaxSetup({
			header:$('meta[name="_token"]').attr('content')
		});
		e.preventDefault(e);

		// console.log(" TYP :" + $(this).data('typ'));

		$.ajax({
	    	type:"POST",
        	url:$(this).data('typ'),
        	data:$(this).serialize(),
        	dataType: 'json',
        	beforeSend: function(){
        		$('#btn_after_seminary_form').css( "opacity", "0" );
        	},
        	success: function(data){
            	$('#row_content').css( "opacity", "1" );
        	},
        	error: function(data){
        		console.log('error case' + data);
	        }
		});
	});

	// Video

	$('.play-container img').on('click', function() {
		var $video = $(this).parent().next('video');
		$(this).hide();
		$video.prop('controls','true');
		$video[0].play();
	});


	// Smooth scroll

	$('a[href^="#"]').not('[href="#"]').on('click', function (event) {
	    event.preventDefault();

	    var $hh = $('header').outerHeight(true);

	    $('html, body').animate({
	        scrollTop: $($.attr(this, 'href')).offset().top - $hh
	    }, 500);
	});


	// Back to top

	function detectPosition(top){
		if($(window).scrollTop() > top){
			return 1
		}else{
			return 0;
		}
	}
	
	function fadeTop(x){
		if(x==1){
			$('#top').fadeIn(300)
		}else{
			$('#top').fadeOut(300)
		}
	}

	function actionTop(top){
		x = detectPosition(top);
		fadeTop(x)
	}

	if($('#top').length > 0){

		var top = 220;
		var x = 0;
		actionTop(top)

		$(window).scroll(function(){
			actionTop(top)
		})

		$('#top').click(function(e){
			e.preventDefault();
			$('html,body').animate({'scrollTop':0},750);
		});
	};




	// Resize

	var resizeTimer;

	$(window).on('resize', function(e) {

		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {

	    // Functions
	    paddingtopFirstSection();

	}, 100);

	});

});